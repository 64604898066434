export const mail = "info@philmeinwelt.com";



export const imageData = [
    {
        name: 'map',
        title: 'Berlin',
        format: 'portrait',
        shortDescription: 'berlin',
        longDescription: 'Berlin',
        image: 'map/map.png',
        category: 'Image',
        batches: {
            featured: false,
        },
    },
];

export const aboutMeComponentData = [
    {
        name: 'aboutme-main',
        category: 'AboutMe',
        title: 'Hi, I am Phil!',
        longDescription: 'Full-Stack Developer with 4 years of experience as Managing Director and 10 years as Freelance Producer in the advertising industry who recently graduaded a 1-year intensive training in Full-Stack Web Development (Mern Stack).',
        portraitImage: '../src/aboutme/phil.jpg',
        batches: {
            featured: false,
        },
    },
];

export const socialIconComponentData = [
    // {
    //     category: 'SocialIcon',
    //     name: 'github',
    //     icon: 'icons/github/GitHub-Mark-Light-64px.png',
    //     color: '#000',
    //     link: 'https://github.com/ppphiiil',
    //     batches: {
    //         featured: false,
    //     },
    // },
    {
        category: 'SocialIcon',
        name: 'linkedin',
        icon: 'icons/linkedin.png',
        color: '#fff',
        link: 'https://www.linkedin.com/in/phil-meinwelt-moser/',
        batches: {
            featured: false,
        },
    },
];

export const articlesData = [
    {
        category: 'Article',
        title: 'This is an Article',
        shortDescription: 'I created a copy of the IKEA website',
        longDescription: 'I was interessted in creating a website with customer requirements. So I decided to take the ikea Website as a customer requirements and it has to look like this.',
        date: '10.02.2021',
        linkName: 'Read more',
        batches: {
            featured: false,
        },
    },
    {
        category: 'Article',
        title: 'This is another Article',
        shortDescription: 'I was interessted in creating a website with customer requirements. So I decided to take the',
        longDescription: 'I was interessted in creating a website with customer requirements. So I decided to take the ikea Website as a customer requirements and it has to look like this.',
        date: '10.02.2021',
        linkName: 'Read more',
        batches: {
            featured: false,
        },
    },
];
